import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "me-4 pe-1" }
const _hoisted_4 = {
  key: 0,
  class: "container loader-container"
}
const _hoisted_5 = { class: "page-body mt-4 pt-3" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "shadow-600 border-radius-1" }
const _hoisted_9 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberUsageModal = _resolveComponent("NumberUsageModal")!
  const _component_DeleteUserDialog = _resolveComponent("DeleteUserDialog")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_FullscreenLoader = _resolveComponent("FullscreenLoader")!
  const _component_AccountInfo = _resolveComponent("AccountInfo")!
  const _component_AppCollapsable = _resolveComponent("AppCollapsable")!
  const _component_AccountStatus = _resolveComponent("AccountStatus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NumberUsageModal, {
      opened: !!_ctx.usageLogsActiveModalId,
      onHide: _ctx.hideUsageLogsModal
    }, null, 8, ["opened", "onHide"]),
    _createVNode(_component_DeleteUserDialog, {
      opened: _ctx.deleteUserDialogShown,
      "user-email": _ctx.accountInfo?.email,
      "user-name": _ctx.accountInfo?.fullName,
      "user-photo": _ctx.accountInfo?.image,
      loading: _ctx.isLoading,
      onHide: _ctx.cleanData,
      onCancel: _ctx.cleanData,
      onConfirm: _ctx.removeUserHandler
    }, null, 8, ["opened", "user-email", "user-name", "user-photo", "loading", "onHide", "onCancel", "onConfirm"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BackButton)
        ]),
        _createElementVNode("h1", null, _toDisplayString(_ctx.userName), 1)
      ]),
      (_ctx.useLoader && _ctx.appLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createVNode(_component_FullscreenLoader)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_AppCollapsable, null, {
                default: _withCtx(() => [
                  _createVNode(_component_AccountInfo, {
                    "user-type": 'user',
                    "user-id": _ctx.userId,
                    "user-information": _ctx.userInformation,
                    onUploadAvatar: _ctx.uploadAvatar,
                    onEditUser: _ctx.editUser
                  }, null, 8, ["user-id", "user-information", "onUploadAvatar", "onEditUser"])
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_AccountStatus, {
              "blocked-user": _ctx.blocked,
              "user-id": _ctx.userId,
              "email-verified": _ctx.emailVerified,
              onDelete: _ctx.onUserDelete
            }, null, 8, ["blocked-user", "user-id", "email-verified", "onDelete"])
          ])
        ])
      ])
    ])
  ], 64))
}